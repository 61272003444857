import { validate } from '../../main/validate';

import { NEWS_ADMIN_ITEM_UPDATE_DATA_DATA_NAME } from './news-admin-item-update-data.constant';

import {
  requiredArray,
  arrayLengthMax,
  numberPositive,
  url,
  maxLength,
} from '../../main/validate/validate.service';

const config = {
  [NEWS_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.ORDER]: [numberPositive],
  [NEWS_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.PREVIEW]: [
    requiredArray,
    arrayLengthMax(1),
  ],
  [NEWS_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.TITLE]: [maxLength(500)],
  [NEWS_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.URL]: [url, maxLength(500)],
  [NEWS_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.BODY]: [maxLength(100000)],
  [NEWS_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.CATEGORY]: [],
  [NEWS_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.DESCRIPTION]: [maxLength(500)],
  [NEWS_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.LANG]: [],
};

export const newsAdminItemUpdateDataFormValidation = (values) =>
  validate(values, config);
